import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import ArticlePage from '~/components/ArticlePage/ArticlePage';
import { Article } from '../types/types';

const AmbassadorNewsTemplate: React.FC<AmbassadorNewsTemplateProps> = ({
  data,
}) => {
  const { news } = data;

  return (
    <Layout>
      <Metadata title={news.title} />
      <ArticlePage article={news} />
    </Layout>
  );
};

export const query = graphql`
  query AmbassadorNewsPageQuery($slug: String, $locale: String) {
    news: contentfulAmbassadorNews(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...AmbassadorNewsPageFragment
    }
  }
`;

interface AmbassadorNewsTemplateProps {
  data: {
    news: Article;
  };
}

export default AmbassadorNewsTemplate;
